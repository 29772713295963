body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Poppins", "Lexend", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.headtext {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  position: relative;
  color: #05174b;
}

.under {
  display: flex;
  flex-direction: row;
  color: #ff7e7c !important;
  padding-left: 40%;
  padding-right: 40%;
  font-size: 12px;
}

.under:before,
.under:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}

.under:before {
  margin-right: 10px;
}

.under:after {
  margin-left: 10px;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #ff7e7c !important;
}

.lexendFont {
  font-family: Lexend, sans-serif;
}

.themeBtn,
.themeUserbtn,
.commentBtn,
.verifyBtn,
.filterBtn {
  background-color: #ff7e7c !important;
  color: white !important;
  font-family: "Poppins", sans-serif !important;
  border: 1.5px solid transparent !important;
  font-size: 0.85rem !important;
  border-radius: 0.5rem;
  font-weight: normal !important;

}



.commentBtn {
  border-radius: 0.5rem !important;
  min-width: 5rem;
  padding: 0.5rem 0.5rem !important;
}

.filterBtn {
  border-radius: 0.5rem !important;
  width: 7rem !important;
  padding: 0.5rem 0.5rem !important;
}

.applyFilterBtn {
  border-radius: 0.5rem !important;
  width: 8rem !important;
  padding: 0.5rem 0.5rem !important;
}

.themeUserbtn {
  border-radius: 8rem !important;
  min-width: 8rem;
}

.verifyBtn {
  position: relative;
  left: 55%;
  border-radius: 8rem !important;
  min-width: 5rem;
}

.themeBtnSecondary {
  color: #ff7e7c !important;
  font-size: 0.85rem !important;
  font-weight: normal !important;
  background-color: transparent !important;
}

.themeBtnSecondary:hover {
  background-color: #e7ebf3 !important;
  color: black !important;
}

.themeBtnOutline,
.themeBtn:hover,
.themeUserbtn:hover,
.commentBtn:hover,
.verifyBtn:hover,
.filterBtn:hover,
.applyFilterBtn {
  color: #ff7e7c !important;
  font-family: "Poppins", sans-serif !important;
  border: 1.5px solid #ff7e7c !important;
  background-color: white !important;
  font-size: 0.85rem !important;
  border-radius: 0.5rem !important;
  font-weight: normal !important;
}

.themeUserbtn:hover,
.verifyBtn:hover {
  border-radius: 8rem !important;
}

.themeBtnOutline:hover,
.applyFilterBtn:hover {
  background-color: #ff7e7c !important;
  color: white !important;
  /* border: 1.5px solid #ff7e7c !important;
  font-size: 0.8rem !important; */
}

.importExcelBtn {
  color: #ff7e7c !important;
  font-family: "Poppins", sans-serif !important;
  border: 1.5px solid #ff7e7c !important;
  background-color: white !important;
  font-size: 0.85rem !important;
  border-radius: 0.5rem !important;
  font-weight: normal !important;
}

.statusBtn {
  padding: 0.5vh 1.5vw !important;
  border: none !important;
  border-radius: 30px !important;
  cursor: default !important;
  font-size: 0.75rem !important;
}

.btn-span {
  display: block;
  height: 10px;
  width: 10px;
}

.custom-progress-bar .p-progressbar .p-progressbar-value {
  transition: width 0.5s ease-in-out;
  position: relative;
  z-index: 1;
}

.progressSection {
  height: 100% !important;
}

.custom-progress-bar .p-progressbar .p-progressbar-value:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right,
      rgba(255, 198, 198, 0.3),
      rgba(255, 255, 255, 0));
  transform: translateX(-100%);
  animation: shine 1.4s infinite;
}

@keyframes shine {
  0% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(100%);
    opacity: 0.5;
  }

  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.pink-btn-health {
  background-color: #ff7e7c !important;
  border-radius: 10px !important;
  color: white !important;
}

.total-btn-health {
  background-color: #9e9e9e !important;
  border-radius: 10px !important;
}

.pass-btn-health,
.secured-btn-health {
  background-color: #2cae21 !important;
  border-radius: 10px !important;
}

.others-btn-health {
  background-color: #9e9e9e !important;
  border-radius: 10px !important;
}

.fail-btn-health,
.critical-btn-health {
  background-color: #ea3e3e !important;
  border-radius: 10px !important;
}

.warn-btn-health,
.vulnerable-btn-health {
  background-color: #ff6c37 !important;
  border-radius: 10px !important;
}

.info-btn-health {
  background-color: #4984a3 !important;
  border-radius: 10px !important;
}

.exe-btn-health {
  background-color: #9e9e9e !important;
  border-radius: 10px !important;
}

.err-btn-health {
  background-color: #ab0404 !important;
  border-radius: 10px !important;
}

.total-btn {
  background-color: #05174b !important;
}

.pass-btn {
  background-color: #2cae21 !important;
}

.automation-btn {
  background-color: #0090c2 !important;
}

.manual-btn {
  background-color: #514396 !important;
}

.fail-btn,
.critical-btn {
  background-color: #ea3e3e !important;
}

.warn-btn,
.vulnerable-btn {
  background-color: #ff6c37 !important;
}

.info-btn {
  background-color: #4984a3 !important;
}

.exe-btn {
  background-color: #9e9e9e !important;
}

.pending-btn {
  background-color: #9e9e9e !important;
}

.err-btn {
  background-color: #ab0404 !important;
}

.resolved-btn {
  background-color: #198754 !important;
}

.open-btn {
  background-color: rgb(204, 201, 6) !important;
}

.others-btn {
  background-color: #bbb !important;
}

.nav-link {
  color: gray !important;
  font-weight: 600 !important;
}

.secure-high-btn,
.secureDast-high-btn {
  background-color: rgb(234, 62, 62);
}

.secure-medium-btn,
.secureDast-medium-btn {
  background-color: rgb(234, 62, 62, 0.7);
}

.secure-low-btn {
  background-color: rgb(255, 108, 55, 0.7);
}

.secureDast-low-btn {
  background-color: rgba(0, 126, 0, 0.7);
}

.secure-info-btn,
.secureDast-info-btn {
  background-color: rgb(73, 132, 163, 0.7);
}

.pass-color {
  color: #2cae21 !important;
}

.fail-color {
  color: #ea3e3e !important;
}

.warn-color {
  color: #ff6c37 !important;
}

.info-color {
  color: #4984a3 !important;
}

.exe-color {
  color: #9e9e9e !important;
}

.pending-color {
  color: #9e9e9e !important;
}

.err-color {
  color: #ab0404 !important;
}

.pDetails-heading {
  color: #787d90 !important;
  font-size: 0.8rem !important;
}

.reqDetails-heading {
  color: #787d90 !important;
  font-size: 0.85rem !important;
}

.pDetails-smheading {
  color: #787d90 !important;
  font-size: 0.65rem !important;
}

.color-grey {
  color: #787d90 !important;
}

.color-light-grey {
  color: #383838 !important;
  opacity: 0.5;
}

.heading1 {
  font-size: 0.875rem !important;
  text-transform: capitalize;
  font-family: "Lexend", sans-serif;
}

.heading2 {
  font-size: 0.75rem !important;
  font-family: "Lexend", sans-serif;
}

.heading3 {
  font-size: 0.675rem !important;
  font-family: "Lexend", sans-serif;
  margin: 4px 0;
}

@media screen and (max-device-width: 768px) {
  .under {
    display: flex;
    flex-direction: row;
    color: #ff7e7c !important;
    padding-left: 20%;
    padding-right: 20%;
    font-size: 12px;
  }
}

.emptysvg {
  width: 40vw !important;
  height: 45vh !important;

  overflow: hidden !important;
  /* -webkit-animation: spin 12s linear infinite;
  -moz-animation: spin 12s linear infinite;
  animation: spin 12s linear infinite; */
}

.jewel-logo {
  -webkit-animation: spin 12s linear infinite;
  -moz-animation: spin 12s linear infinite;
  animation: spin 12s linear infinite;
}

.jewel-logoanimate {
  -webkit-animation: spin 12s linear infinite;
  -moz-animation: spin 12s linear infinite;
  animation: spin 12s linear infinite;
}

.p-datatable-wrapper>.p-datatable-table>.p-datatable-tbody>tr {
  border-bottom: 1px solid #d1dae7 !important;
}

.staticIcon {
  color: #205072 !important;
}

.actionBtn {
  cursor: pointer !important;
  color: #205072 !important;
}

.warningBtn {
  color: #f6c700 !important;
}

.actionBtn:hover {
  transform: scale(1.2);
  cursor: pointer !important;
}

.p-virtualscroller.p-multiselect-items-wrapper {
  /* height: 43px !important; */
  overflow-x: hidden !important;
  min-height: 200px !important;
}

.clickable {
  cursor: pointer !important;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.emptyText {
  background-color: #dedede !important;
  color: black !important;

  font-family: "Poppins", sans-serif;
  cursor: pointer !important;
  border-radius: 10px !important;
  font-size: 0.85rem !important;
  border: none !important;
}

.emptyText:hover {
  background-color: #ffa8a7 !important;
  color: white !important;
}

.hide {
  display: none !important;
}

.p-datatable .p-column-header-content {
  display: inline-block !important;
  vertical-align: middle !important;
}

.linkStyle {
  cursor: pointer;
  color: blue;
}

.expanderheadercolumnsPrime {
  border: none !important;
  font-size: 0.95rem !important;
  background-color: rgba(211, 211, 211, 1) !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
  /* color: #343434 !important; */
}

.expandercolumnsPrime {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border: none !important;
}

.columnsPrime {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  /* max-width: 20vw !important; */
  border: none !important;
  font-size: 0.8rem !important;
  /* white-space: none !important; */
  word-wrap: break-word;
}

.seeMoreWidth {
  max-width: 17.99vw !important;
  white-space: break-spaces !important;
}

/* .testStep .headercolumnsPrime {
  z-index: 0.5 !important;
} */
.p-sortable-column-icon {
  font-size: 0.7rem !important;
  margin: 10px !important;
}

.headercolumnsPrime {
  border: none !important;
  font-size: 0.8rem !important;
  background-color: rgba(211, 211, 211, 1) !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
  /* color: #343434 !important; */
}

.headingFont {
  font-size: 0.85rem !important;
}

/* .maxWidthTableText {
  max-width: 250px !important;
  word-wrap: break-word !important;
  white-space: normal !important;
} */
.p-knob-text {
  font-size: 0.9rem !important;
  font-weight: bold !important;
}

.reportIcons {
  padding: 2px 8px !important;
  background-color: white !important;
  color: #ff7e7c !important;
  cursor: pointer !important;
  font-size: 0.85rem !important;
  border: 1px solid white !important;
  border-radius: 8% !important;
}

.seeMoreWrap {
  word-break: break-word !important;
}

.reportIcons:hover {
  transform: scale(1.05) !important;
  border: 1px solid #ff7e7c !important;
}

.seeMoreLink {
  color: blue !important;
  cursor: pointer !important;
}

.seeMoreHeader {
  text-align: center !important;
}

.seeMoreDialogContent {
  max-width: 100% !important;
  word-wrap: break-word !important;
}

.p-column-filter-menu-button.p-link {
  color: grey !important;
  font-size: calc(0.8vh + 0.8vw) !important;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active {
  color: #ff7e7c !important;
  font-size: calc(0.8vh + 0.8vw) !important;
}

.pi.pi-filter-icon.pi-filter {
  font-size: 0.8rem !important;
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  display: none !important;
}

/* .p-datatable-table {
  min-height: 60vh !important;
} */
:root {
  background-color: #f6f7fc !important;
  /* box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08) !important; */
}

.fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 300ms ease;
}

.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);

  transition: opacity 250ms ease-out, transform 300ms ease;
}

.skeloverHead {
  display: flex;
  flex-direction: row;
  padding-left: 28%;
  padding-right: 28%;
  justify-content: center !important;
}

.overHeadAnalytics {
  color: #023b59 !important;
  font-size: 1.5rem !important;
  font-style: normal;
  line-height: 38px;
  display: flex;
  flex-direction: row;
  padding-left: 28%;
  padding-right: 28%;
}

.overHeadAnalytics:before,
.overHeadAnalytics:after {
  content: "";
  flex: 1 1;
  border-bottom: 1.2px solid;
  margin: auto;
}

.overHeadAnalytics:before {
  margin-right: 10px;
}

.overHeadAnalytics:after {
  margin-left: 10px;
}

.pillBg {
  background-color: #ff7e7c !important;
  font-size: 0.75rem !important;
  color: white;
  font-family: "Poppins", sans-serif !important;
  border-radius: 8px !important;
}

.inputHeight {
  min-height: 9vh !important;
  max-height: 9vh !important;
  border: 1px solid #ced4da !important;
}

.chipsenv>.p-chips-multiple-container {
  border-radius: 6px !important;
  border: 1px solid #ced4da !important;
}

.inputHeight .p-dropdown-label.p-inputtext {
  border: unset !important;
}

.chipHeight .p-inputtext.p-chips-multiple-container {
  overflow: auto !important;
  max-height: 9vh !important;
  border: 1px solid #ced4da !important;
  max-width: 20vw !important;
}

.maxStatusWidth {
  max-width: 5% !important;
}

.custom-progressbar {
  min-height: 3.5vh !important;
  border-radius: 16px !important;
}

.progressText {
  font-size: 1rem !important;
  color: #023b59 !important;
}

.culpritWidth {
  max-width: 150px !important;
  word-wrap: break-word !important;
  white-space: normal !important;
}

.culpritWidth>.p-progressbar-determinate .p-progressbar-label {
  font-weight: normal !important;
}

.verticalDuration {
  border-left: 2px dashed #205072 !important;
  height: 7.5vh !important;
  /* position: relative;
  left: 50% !important; */
}

.dataTable {
  /* transform: scale(0.75) !important; */
}

.arrow_box {
  position: relative;
  background: #205072;
  /* border: 1px solid #c2e1f5; */
  width: 0%;
}

.arrow_box:after,
.arrow_box:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #205072;
  border-width: 10px;
  margin-left: -10px;
}

.arrow_box:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #205072;
  border-width: 12px;
  margin-left: -12px;
}

.typewriter {
  overflow: hidden;
  /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange;
  /* The typwriter cursor */
  white-space: nowrap;
  /* Keeps the content on a single line */
  margin: 0 auto;
  /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em;
  /* Adjust as needed */
  font-size: 0.85rem !important;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

.iconclick.colorTheme {
  color: #22c55e !important;
}

.iconclick {
  cursor: pointer !important;
  font-size: 1.1rem !important;
}

.iconclick:hover {
  transform: scale(1.1);
}

.changeRoleIcon {
  filter: invert(68%) sepia(20%) saturate(880%) hue-rotate(314deg) brightness(96%) contrast(99%) !important;
  cursor: pointer;
}

.disabledIcon {
  color: #9e9e9e !important;
  cursor: not-allowed !important;
}

.disabledActionIcon {
  cursor: not-allowed !important;
  filter: invert(68%) sepia(20%) saturate(880%) hue-rotate(314deg) brightness(96%) contrast(99%) !important;
  opacity: 0.5 !important;
  font-size: 1.1rem !important;
}

.disabledToggleIcon>* {
  color: #9e9e9e;
  cursor: not-allowed !important;
}

.disabledToggleIcon>.p-inputswitch-slider {
  box-shadow: none !important;
}

.disabledGitIcon {
  color: #9e9e9e;
  cursor: not-allowed !important;
  font-size: 1.1rem !important;
}

.schedulerDisabledIcon {
  color: #9e9e9e;
  cursor: not-allowed !important;
  font-size: 1.1rem !important;
}

.inactiveFVIcon {
  filter: invert(100%) sepia(0%) saturate(2025%) hue-rotate(21deg) brightness(116%) contrast(80%);
}

.activeFVIcon {
  filter: invert(58%) sepia(8%) saturate(1769%) hue-rotate(209deg) brightness(92%) contrast(85%);
}

.doughnut-div-padding {
  padding: 1rem 3rem;
}

.doughnut-legend {
  width: 90% !important;
  margin: 1rem auto auto auto;
}

.doughnut-legend-span {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0 10px 0 0;
}

.secure-doughnut-legend-span {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 4px 0 0;
}

.legend-label {
  font-size: 0.875 !important;
  font-weight: 700;
  color: #615e83;
  font-family: "Lexend", sans-serif;
}

.doughnut-legend-val {
  color: #9291a5;
  font-size: 0.875rem !important;
  font-weight: 400;
  font-family: "Lexend", sans-serif;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {

  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: orange;
  }
}

.blink {
  color: #1e88e5 !important;
  animation: blinker 0.5s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
  margin: 0;
}

@keyframes blinker {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* .blink {
  color : blue;
  margin-right: 0.5rem;
    animation: blink 1s steps(10, start) infinite;
    -webkit-animation: blink 1s steps(10, start) infinite;
  }
  @keyframes blink {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink {
    to {
      visibility: hidden;
    }
  } */
/* #f6f7fc */
.forgotPass>a {
  text-decoration: none !important;
  font-size: 0.9rem !important;
}

.linkwithoutmakeup>a {
  text-decoration: none !important;
  color: black !important;
}

.fontSizeGeneral {
  /* color: #023b59 !important; */
  font-size: 0.8rem !important;
}

.fontSizeHeadGeneral {
  font-size: 1.3rem !important;
}

* {
  font-size: 0.9rem !important;
}

.boldText {
  font-weight: 500 !important;
}

.statusCardsTC {
  font-size: 0.9rem !important;
}

.tc_status {
  min-height: 5% !important;
  min-width: 8% !important;
  /* width: 5% !important; */
  border-radius: 5px !important;
  cursor: pointer !important;
}

.highlightFilter {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.11);
  border: 2px solid black !important;
}

.tc_status:hover {
  transform: scale(1.1);
}

.tc_status_span {
  color: white !important;
  font-weight: 600 !important;
  font-size: 0.9rem !important;
}

.acc-header.p-accordion-header {
  color: black !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.11);
  border-radius: 16px;
}

.acc-header.p-accordion-header a {
  min-width: 100% !important;
}

.acc-heading {
  font-size: 1rem !important;
  word-wrap: break-word !important;
}

.acc-desc-heading {
  color: #023b59 !important;
}

.acc-desc {
  word-wrap: break-word !important;
}

.acc-steps {
  /* box-shadow: none !important; */
  border-radius: none !important;
}

.acc-header>a {
  background-color: white !important;
}

.acc-header>a>.p-accordion-header-text {
  min-width: 100% !important;
  /* word-wrap: break-word !important; */
}

/* .acc-header
{
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.20);

} */
.accordionSteps>.p-accordion>.p-accordion-tab {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.14);
}

.reqIcons {
  color: #05174b !important;
}

.reqLinks {
  cursor: pointer !important;
}

.reqLinks:hover {
  /* border-bottom: 1px solid #05174b !important; */
  text-decoration: underline !important;
  transform: scale(1.02) !important;
}

.badgePill {
  border-radius: 10px !important;
  font-size: 0.7rem !important;
}

.low-color {
  color: #007f00 !important;
  background-color: #cdffcd !important;
}

.info-color {
  color: #027eaf !important;
  background-color: #cdfcff !important;
}

.critical-color {
  color: #ff6b69 !important;
  background-color: #ffeaea !important;
}

.high-color {
  color: #ff7e7c !important;
  background-color: #ffeaea !important;
}

.medium-color {
  color: #eb9217 !important;
  background-color: #fff4e3 !important;
}

.verticalLine {
  border-right: 2px solid #ced4da !important;
}

.activeBtn {
  border: 2px solid #2d3948 !important;
  /* background-color: #007f00 !important; */
  color: white !important;
}

.btnGrey {
  background-color: #d3d3d3 !important;
  color: black !important;
}

.tableWithoutHeader {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12) !important;
  border-radius: 20px !important;
}

/* .tableWithoutHeader > .p-datatable-header
{
 background-color: white !important;
 border-top-right-radius: 20px !important;
 border-top-left-radius: 20px !important;
} */
.tableWithoutHeader>.p-datatable-wrapper {
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
}

.tableWithoutHeader>.p-datatable-wrapper>.p-datatable-table>.p-datatable-thead>tr>th {
  background-color: #2d3948 !important;
  color: white !important;
}

.tableWithoutHeader>.p-paginator {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.tableWithoutHeader>.p-datatable-footer {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.tableWithoutHeader .p-sortable-column .p-sortable-column-icon {
  color: white !important;
}

.test-stepTable {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12) !important;
  border-radius: 20px !important;
}

.test-stepTable>.p-datatable-header {
  background-color: white !important;
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
}

.test-stepTable>.p-datatable-wrapper>.p-datatable-table>.p-datatable-thead>tr>th {
  background-color: #2d3948 !important;
  color: white !important;
}

.test-stepTable>.p-paginator {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.test-stepTable .p-sortable-column .p-sortable-column-icon {
  color: white !important;
}

.test-stepTable>.p-datatable-footer {
  background-color: white !important;
  border: none !important;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  padding: 0 !important;
}

.test-stepTable>.p-datatable-footer>.customPaginator {
  padding: 0.5rem 0.5rem !important;
}

.highligtBorder {
  border: 1.5px solid black !important;
}

.searchHeaderDiv {
  min-width: 75% !important;
}

.vertical-alignment {
  vertical-align: baseline;
}


.p-button-label {
  font-weight: normal !important;
}

.blinktext {
  animation: animate 1.25s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}

.emptyTable {
  height: 20rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
}